import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {did, menu, shortFunction, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
// import AddAndEditShortFunction from "../components/AddAndEditShortFunction";
import {didCreateGet, didDelete, didDeleteLocal, didRequest} from "../store/actions/did";
import Cookies from "js-cookie";
import moment from "moment";
import Api from "../Api";
import EditShortFunction from "../components/EditShortFunction";

function ShortFunction(props) {
    // const didData = useSelector(state => state.did.didData)
    const [edit, setEdit] = useState(0)
    const [have, setHave] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const [short, setShort] = useState([{ member: userData.id, function_code: 10999, day_count: 3},
        { member:userData.id, function_code: 10888, day_count: 3},
        { member: userData.id, function_code: 100097, day_count: 3}])
    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            try{
                const {data} = await Api.shortFunctionGet()
                if(!data.data.length){
                    setShort([{ member: userData.id, function_code: 10999, day_count: 3},
                        { member:userData.id, function_code: 10888, day_count: 3},
                        { member: userData.id, function_code: 100097, day_count: 3}])
                }else{
                    setHave(true)
                    setShort(data.data)
                }
            }catch (e) {

            }
            // await dispatch(didRequest())
        })()
    }, [userData])
    return (
        <>
            <Helmet>
                <title>
                    {menu.shortF[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                            {menu.shortF[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        >
                                            {shortFunction.phone[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {shortFunction.prev[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell align="center">
                                            10999
                                        </TableCell>
                                        <TableCell align="center">
                                            10999
                                        </TableCell>
                                        <TableCell align="center" className="justify-content-center"
                                                   style={{padding: 5}} sx={{display: "flex", padding: 5}}>
                                                <span onClick={() => setEdit(10999)}
                                                      className="edit "><FontAwesomeIcon
                                                    icon={faPenToSquare}/></span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>

                </div>
                {/*{create ? <AddAndEditShortFunction open={create} edit={false} setOpen={setCreate}/> : null}*/}
                {edit ? <EditShortFunction have={have} all={short} open={edit} edit={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default ShortFunction;
