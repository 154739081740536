import {
    FILE_ADD_LOCAL,
    FILE_CREATE_SUCCESS,
    FILE_DELETE_LOCAL,
    FILE_FAIL,
    FILE_IDICT_CREATE_SUCCESS,
    FILE_SUCCESS, FILE_UPDATE_SUCCESS, FILE_YANDEX_CREATE_SUCCESS,
} from '../actions/file';

const initialState = {
    fileStatus: '',
    fileData: [],
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FILE_UPDATE_SUCCESS:
        case FILE_SUCCESS: {
            return {
                ...state,
                fileStatus: 'ok',
                fileData:action.payload.data.file
            };
        }
        case FILE_CREATE_SUCCESS: {
            return {
                ...state,
                fileStatus: 'ok',
                fileData:action.payload.data.file
            };
        }
        case FILE_IDICT_CREATE_SUCCESS: {
            return {
                ...state,
                fileStatus: 'ok',
                fileData:action.payload.data.file
            };
        }
        case FILE_YANDEX_CREATE_SUCCESS: {
            return {
                ...state,
                fileStatus: 'ok',
                fileData:action.payload.data.file
            };
        }
        case FILE_FAIL: {
            return {
                ...state,
                fileStatus: 'fail',
            };
        }
        case FILE_ADD_LOCAL: {
            return {
                ...state,
                fileStatus: 'fail',
                fileData: [...state.fileData, {...action.payload.data, id: new Date()},]
            };
        }
        case FILE_DELETE_LOCAL: {
            return {
                ...state,
                fileStatus: 'fail',
                fileData: state.fileData.filter(c => c.id !== action.payload.id)
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
