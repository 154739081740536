import {
    LOGS_FAIL,
    LOGS_REQUEST,
    LOGS_SUCCESS
} from '../actions/logs';

const initialState = {
    logsData: [],
    logsStatus:'',
    page:1,
    count:0,
    callStatus:{},
    callStatus1:{},
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOGS_REQUEST: {
            return {
                ...state,
                logsStatus: 'request',
                logsData: [],
            };
        }
        case LOGS_SUCCESS: {
            return {
                ...state,
                logsStatus: 'success',
                logsData: action.payload.data.logs,
                page:action.payload.data.page,
                count:action.payload.data.count,

            };
        }
        case LOGS_FAIL: {
            return {
                ...state,
                logsStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
