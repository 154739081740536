import React, {useEffect, useState} from 'react';

function DisplayTrack({ currentTrack,audioRef,setDuration,
                          progressBarRef,  }) {
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
    };
    const [audioSrc, setAudioSrc] = useState('');

    const fetchAudio = async () => {
        try {
            const response = await fetch(currentTrack?.src);
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            setAudioSrc(fileURL);
        } catch (error) {
            console.error('Error fetching audio file:', error);
        }
    };
    useEffect(() => {
        (async () => await fetchAudio())()
    }, [currentTrack?.src]);
    return (
        <div className="d-flex align-items-center">
            <audio src={audioSrc} ref={audioRef} onLoadedMetadata={onLoadedMetadata}/>
            <div className="audio-info">
                <div className="text">
                    <p className="title">{currentTrack.type === 'Outgoing'?
                        <div data-v-3ab865ea=""
                             className="itl-svg svg-call-outgoing-24 with-text"
                             style={{color: 'rgb(80, 176, 245)'}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                                <path
                                    d='M13.293 14.707a1 1 0 0 0 1.414-1.414L7.414 6H12a1 1 0 1 0 0-2H7a3 3 0 0 0-3 3v5a1 1 0 1 0 2 0V7.414l7.293 7.293zM20 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0z'
                                    fill="currentColor"/>
                            </svg>
                        </div>:
                        <div data-v-3ab865ea=""
                             className="itl-svg svg-call-outgoing-24 with-text"
                             style={{color: 'rgb(118, 209, 44)'}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                                <path
                                    d='M4.293 4.293a1 1 0 0 1 1.414 0L13 11.586V7a1 1 0 1 1 2 0v5a3 3 0 0 1-3 3H7a1 1 0 1 1 0-2h4.586L4.293 5.707a1 1 0 0 1 0-1.414zM18 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'
                                    fill="currentColor"/>
                            </svg>
                        </div>

                    } </p>
                    <p>{currentTrack.id}</p>
                </div>
            </div>
        </div>
    );
}

export default DisplayTrack;
