import React, {useCallback} from 'react';
import {Fade, Paper, Popper} from "@mui/material";
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import qs from "query-string";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

function TestPop() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleSearch = useCallback((ev) => {
        query.testmode = ev;
        query.page = '';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    return (
        <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
            {(popupState) => (
                <div className="d-flex align-items-center justify-content-center"
                     style={{overflow: 'hidden', width: '100%'}}>
                    <div style={{cursor: 'pointer',}}
                         className="d-flex align-items-center justify-content-center w-100 " {...bindToggle(popupState)}>
                        Test <ArrowDropDownIcon/>
                    </div>
                    <Popper className="profile__down" {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                            <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                <Paper>
                                    <p onClick={() => handleSearch('')} className="flags">
                                        Բոլորը
                                    </p>

                                    <p onClick={() => handleSearch(1)} className='flags'>
                                        Այո
                                    </p>
                                    <p onClick={() => handleSearch(0)} className='flags'>
                                        Ոչ
                                    </p>

                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            )}
        </PopupState>
    );
}

export default TestPop;
