import {call, put, takeLatest} from 'redux-saga/effects';
import {
    FILE_CREATE_FAIL,
    FILE_CREATE_REQUEST,
    FILE_CREATE_SUCCESS,
    FILE_DELETE_FAIL,
    FILE_DELETE_REQUEST,
    FILE_DELETE_SUCCESS,
    FILE_FAIL,
    FILE_IDICT_CREATE_FAIL,
    FILE_IDICT_CREATE_REQUEST,
    FILE_IDICT_CREATE_SUCCESS,
    FILE_REQUEST,
    FILE_SUCCESS,
    FILE_UPDATE_FAIL,
    FILE_UPDATE_REQUEST, FILE_UPDATE_SUCCESS,
    FILE_YANDEX_CREATE_FAIL,
    FILE_YANDEX_CREATE_REQUEST,
    FILE_YANDEX_CREATE_SUCCESS,
} from '../actions/file';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleFileCreateRequest(action) {
    try {
        const {form} = action.payload;
        const {data} = yield call(Api.fileCreate, form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: FILE_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleFileUpdateRequest(action) {
    try {
        const {id,musicHold} = action.payload;
        const {data} = yield call(Api.fileEdit, id,musicHold);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: FILE_UPDATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_UPDATE_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleFileIdictCreateRequest(action) {
    try {
        const {form} = action.payload;
        const {data} = yield call(Api.fileIdict, form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: FILE_IDICT_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_IDICT_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleFileYandexCreateRequest(action) {
    try {
        const {form} = action.payload;
        const {data} = yield call(Api.fileYandex, form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: FILE_YANDEX_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_YANDEX_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleFileDeleteRequest(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(Api.fileDelete, id);
        toast.success(global.deleted[+localStorage.getItem('atsLang')])

        yield put({
            type: FILE_DELETE_SUCCESS,
            payload: {},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_DELETE_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleFileRequest(action) {
    try {
        const {data} = yield call(Api.fileGet);
        yield put({
            type: FILE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FILE_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(FILE_CREATE_REQUEST, handleFileCreateRequest);
    yield takeLatest(FILE_IDICT_CREATE_REQUEST, handleFileIdictCreateRequest);
    yield takeLatest(FILE_YANDEX_CREATE_REQUEST, handleFileYandexCreateRequest);
    yield takeLatest(FILE_REQUEST, handleFileRequest);
    yield takeLatest(FILE_UPDATE_REQUEST, handleFileUpdateRequest);
    yield takeLatest(FILE_DELETE_REQUEST, handleFileDeleteRequest);

}
