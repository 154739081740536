import {call, put, takeLatest} from 'redux-saga/effects';
import {
    AUTO_CALL_CREATE_FAIL, AUTO_CALL_CREATE_REQUEST,
    AUTO_CALL_CREATE_SUCCESS, AUTO_CALL_DELETE_FAIL, AUTO_CALL_DELETE_REQUEST, AUTO_CALL_DELETE_SUCCESS,
    AUTO_CALL_FAIL,
    AUTO_CALL_REQUEST, AUTO_CALL_STATUS_FAIL, AUTO_CALL_STATUS_REQUEST, AUTO_CALL_STATUS_SUCCESS,
    AUTO_CALL_SUCCESS,
} from '../actions/autoCall';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleAutoCall(action) {
    try {
        const {data} = yield call(Api.getAuto);
        yield put({
            type: AUTO_CALL_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: AUTO_CALL_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleAutoCallCreate(action) {
    try {
        const {data} = yield call(Api.createAuto,action.payload.data);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: AUTO_CALL_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: AUTO_CALL_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleAutoCallStatus(action) {
    try {
        const {data} = yield call(Api.editAutoStatus,action.payload.id,action.payload.form);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: AUTO_CALL_STATUS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: AUTO_CALL_STATUS_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleAutoCallDelete(action) {
    try {
        const {data} = yield call(Api.deleteAuto,action.payload.id);
        toast.success(global.deleted[+localStorage.getItem('atsLang')])

        yield put({
            type: AUTO_CALL_DELETE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: AUTO_CALL_DELETE_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(AUTO_CALL_REQUEST, handleAutoCall);
    yield takeLatest(AUTO_CALL_STATUS_REQUEST, handleAutoCallStatus);
    yield takeLatest(AUTO_CALL_CREATE_REQUEST, handleAutoCallCreate);
    yield takeLatest(AUTO_CALL_DELETE_REQUEST, handleAutoCallDelete);

}
