import {call, put, takeLatest} from 'redux-saga/effects';
import {
    BLOCKED_CREATE_FAIL, BLOCKED_CREATE_REQUEST,
    BLOCKED_CREATE_SUCCESS,
    BLOCKED_FAIL,
    BLOCKED_REQUEST,
    BLOCKED_SUCCESS,
} from '../actions/blocked';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleBlockedRequest(action) {
    try {
        const {limit,page,search} = action.payload;
        const {data} = yield call(Api.getBlocked, limit,page,search);
        yield put({
            type: BLOCKED_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: BLOCKED_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleBlockedCreateRequest(action) {
    try {
        const {limit,page,search,form} = action.payload;
        const {data} = yield call(Api.createBlocked, limit,page,search,form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: BLOCKED_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: BLOCKED_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(BLOCKED_REQUEST, handleBlockedRequest);
    yield takeLatest(BLOCKED_CREATE_REQUEST, handleBlockedCreateRequest);

}
