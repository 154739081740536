import React from 'react';
import {Link} from "react-router-dom";
import img from "../assets/img/logo22.png";
import ats from "../assets/img/logo_light.png";
import uc from "../assets/img/image_ucom.png";
import {menu} from "../translate";
import LockIcon from "@mui/icons-material/Lock";
const {REACT_APP_SERVER} = process.env;

function UrsHeader(props) {
    return (
        <header className="header">
            <div className="header_container container" style={window.innerWidth > 1030 ? {} : {maxWidth: '100%'}}>
                <div className="header_block">
                    <div className="left">
                        {REACT_APP_SERVER === 'Beeline' ? <Link to="/urs">
                            <figure>
                                <img style={{width: 100}} src={img} alt=''/>
                            </figure>
                        </Link> : REACT_APP_SERVER === 'ATS' ? <Link to="/urs">
                            <figure>
                                <img style={{width: 100}} src={ats} alt=''/>
                            </figure>
                        </Link> : <Link to="/urs">
                            <figure>
                                <img src={uc} alt=''/>
                            </figure>
                        </Link>}

                    </div>
                    <div className="right">
                        <div className="logout">
                            <Link to="/urs_online" target="_blank"
                                  rel="noopener noreferrer"> {menu.online[1]}</Link>
                        </div>
                        <div className="logout">
                            <Link to="/admin_login"><LockIcon
                                sx={{color: '#CD405F', fontSize: 20}}/> {menu.logout[1]}</Link>
                        </div>

                    </div>

                </div>
            </div>
        </header>
    );
}

export default UrsHeader;
