import React, {useCallback} from 'react';
import {Button, Fade, Paper, Popover, Popper, Typography} from "@mui/material";
import PopupState, {bindTrigger, bindPopover, bindToggle, bindPopper} from 'material-ui-popup-state';
import arm from '../assets/img/arm.png'
import ru from '../assets/img/ru.png'
import eng from '../assets/img/eng.png'
function LangPop({setLang}) {
    const handleLangChange = useCallback((lang) => {
        setLang(lang)
        localStorage.setItem('atsLang',lang)
        window.location.reload()
    }, [])
    return (
        <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
            {(popupState) => (
                <div className="d-flex align-items-center justify-content-center" style={{overflow:'hidden', width:'100%'}}>
                    <div style={{cursor:'pointer',padding:20 }} className="d-flex align-items-center justify-content-center w-100 " {...bindToggle(popupState)}>
                          language
                        <figure className="langBlock">
                            <img src={+localStorage.getItem('atsLang')===1?eng:+localStorage.getItem('atsLang') === 2?ru:arm} alt="russian"/>
                        </figure>
                    </div>
                 <Popper className="profile__down" {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                            <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                <Paper>
                                    <figure onClick={() => handleLangChange(3)} className="flags">
                                        <img src={arm} alt="armenia"/>
                                        <figcaption>Armenian</figcaption>
                                    </figure>
                                    <figure onClick={() => handleLangChange(2)}  className="flags">
                                        <img src={ru} alt="russian"/>
                                        <figcaption>Russian</figcaption>
                                    </figure>
                                    <figure onClick={() => handleLangChange(1)} className="flags">
                                        <img src={eng} alt="english"/>
                                        <figcaption>English</figcaption>
                                    </figure>

                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            )}
        </PopupState>
    );
}

export default LangPop;
