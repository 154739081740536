import React, {useCallback, useEffect, useState} from 'react';
import Api from "../Api";
import {toast} from "react-toastify";
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {global} from "../translate";
import {REACT_APP_API_URL} from '../config';
import Account from "../helpers/Account";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '300px',
    overflow: 'auto',
};

function UrsReport({open, setOpen,}) {
    const [report,setReport] = useState({})
    useEffect(() => {
        (async () => {
            const {data} = await Api.getReport(open === 'prev')
            setReport(data)
        })()
    }, [open])

    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => setOpen('')}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Report
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen('')}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row w-100">
                                Cost: {report.ggumar}
                                Price: {report.ggumarsp}
                                <a href={REACT_APP_API_URL + `/users/month_report_download?token=${Account.getUrsToken()}&path=` + report.text}>Download text</a>
                                <a href={REACT_APP_API_URL + `/users/month_report_download?token=${Account.getUrsToken()}&path=` + report.csv}>Download CSV</a>
                            </div>
                            <div className="d-flex justify-content-center">

                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );

}

export default UrsReport;
