import React, {useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Api from "../Api";
import {didRequest} from "../store/actions/did";
import {Helmet} from "react-helmet";

function Online(props) {
    const [online, setOnline] = useState([])
    useEffect(() => {
        (async () => {
            try {
                const intervalId = setInterval(async () => {
                    const {data} = await Api.online()
                    setOnline(data.rows)
                }, 1000);

                return () => {
                    clearInterval(intervalId);
                };
            } catch (e) {
            }
        })()
    }, [])
    return (
       <>
           <Helmet>
               <title>Online</title>
           </Helmet>
           <div className="container">
               <div style={{marginTop:45}} className="tableParent">
                   <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                       <TableContainer
                       >
                           <Table stickyHeader aria-label="sticky table">
                               <TableHead>
                                   <TableRow className="sip_header">
                                       <TableCell sx={{fontSize: 20,}} align="left" colSpan={6}>
                                           Online
                                       </TableCell>
                                   </TableRow>
                                   <TableRow className="sip_num">
                                       <TableCell
                                           align='center'
                                       >
                                           Start time
                                       </TableCell>

                                       <TableCell
                                           align='center'
                                       >
                                           Duration
                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           Status
                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           Description
                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           From
                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           To
                                       </TableCell>

                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   {online.map(s => (
                                       <TableRow key={s.pref + s.did} hover role="checkbox" tabIndex={-1}>
                                           <TableCell align="center">
                                               {new Date(s.start).toLocaleString()}
                                           </TableCell>
                                           <TableCell align="center">
                                               {s?.ctime}
                                           </TableCell>
                                           <TableCell align="center">
                                               {s?.row2[0]?.disposition}
                                           </TableCell>
                                           <TableCell align="center">
                                               {s?.row3[0]?.anun}
                                           </TableCell>
                                           <TableCell align="center">
                                               {s?.ext_con ? s.ext_con + '-':''}
                                               {s?.extension}
                                           </TableCell>
                                           <TableCell align="center">
                                               {s?.dst_con ? s.dst_con + '-':''}
                                               {s?.destination}
                                           </TableCell>
                                       </TableRow>
                                   ))
                                   }
                               </TableBody>
                           </Table>
                       </TableContainer>
                   </Paper>

               </div>

           </div>
       </>
    );
}

export default Online;
