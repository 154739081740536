import { takeLatest, call, put } from 'redux-saga/effects';
import {
    DID_REQUEST,
    DID_SUCCESS,
    DID_FAIL,
    DID_CREATE_GET_REQUEST,
    DID_CREATE_GET_SUCCESS,
    DID_CREATE_GET_FAIL,
    DID_CREATE_SUCCESS,
    DID_CREATE_FAIL,
    DID_CREATE_REQUEST,
    DID_EDIT_SUCCESS,
    DID_EDIT_FAIL,
    DID_EDIT_REQUEST,
    DID_DELETE_REQUEST,
    DID_DELETE_SUCCESS,
    DID_DELETE_FAIL,
} from '../actions/did';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from '../../translate'
function* handleDid(action) {
    try {
        const { data } = yield call(Api.getDid,action.payload.search,action.payload.limit,action.payload.page,);
        yield put({
            type:  DID_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type:  DID_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleDidCreateGet(action) {
    try {
        const { data } = yield call(Api.getCreateDid, );
        yield put({
            type:  DID_CREATE_GET_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type:  DID_CREATE_GET_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleDidCreate(action) {
    try {
        const {form,limit,page} = action.payload
        const { data } = yield call(Api.createDid, form,limit,page);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type:  DID_CREATE_SUCCESS,
            payload: { data },
        });

    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type:  DID_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleDidEdit(action) {
    try {
        const {form,provider,limit,page} = action.payload
        const { data } = yield call(Api.editDid, form, provider,limit,page);
        toast.success(global.success[+localStorage.getItem('atsLang')])
        yield put({
            type:  DID_EDIT_SUCCESS,
            payload: { data },
        });

    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type:  DID_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleDidDelete(action) {
    try {
        const {phone,provider} = action.payload
        yield call(Api.deleteDid, phone,provider);
        toast.success(global.deleted[+localStorage.getItem('atsLang')])

        yield put({
            type:  DID_DELETE_SUCCESS,
            payload: { },
        });

    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type:  DID_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}


export default function* watcher() {
    yield takeLatest( DID_REQUEST, handleDid);
    yield takeLatest( DID_CREATE_GET_REQUEST, handleDidCreateGet);
    yield takeLatest( DID_CREATE_REQUEST, handleDidCreate);
    yield takeLatest( DID_EDIT_REQUEST, handleDidEdit);
    yield takeLatest( DID_DELETE_REQUEST, handleDidDelete);
}
