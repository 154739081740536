import { takeLatest, call, put } from 'redux-saga/effects';
import {

    IVR_REQUEST,
    IVR_SUCCESS,
    IVR_FAIL,
    IVR_CREATE_REQUEST,
    IVR_CREATE_SUCCESS,
    IVR_CREATE_FAIL,
    IVR_EDIT_SUCCESS,
    IVR_EDIT_FAIL,
    IVR_EDIT_REQUEST,
} from '../actions/ivr';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleIvr(action) {
    try {
        const {limit,page,search,sort,sortBy} = action.payload
        const { data } = yield call(Api.IVR, limit,page,search,sort,sortBy);
        yield put({
            type: IVR_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: IVR_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleIvrCreate(action) {
    try {
        const {limit,page,search,form} = action.payload
        const { data } = yield call(Api.createIvr, limit,page,search,form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: IVR_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: IVR_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleIvrEdit(action) {
    try {
        const {limit,page,search,id,form} = action.payload
        const { data } = yield call(Api.editIvr, limit,page,search,id,form);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: IVR_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: IVR_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}


export default function* watcher() {
    yield takeLatest(IVR_REQUEST, handleIvr);
    yield takeLatest(IVR_CREATE_REQUEST, handleIvrCreate);
    yield takeLatest(IVR_EDIT_REQUEST, handleIvrEdit);
}
