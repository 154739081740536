export const IP_REQUEST = 'IP_REQUEST';
export const IP_SUCCESS = 'IP_SUCCESS';
export const IP_FAIL = 'IP_FAIL';

export function ipRequest(limit,page,search) {
  return {
    type: IP_REQUEST,
    payload: { limit,page,search },
  };
}

export const IP_CREATE_REQUEST = 'IP_CREATE_REQUEST';
export const IP_CREATE_SUCCESS = 'IP_CREATE_SUCCESS';
export const IP_CREATE_FAIL = 'IP_CREATE_FAIL';

export function ipCreateRequest(ip) {
  return {
    type: IP_CREATE_REQUEST,
    payload: { ip },
  };
}
export const IP_ADD_LOCAL = 'IP_ADD_LOCAL';

export function ipLocal(data) {
  return {
    type: IP_ADD_LOCAL,
    payload: { data },
  };
}
export const IP_DELETE_LOCAL = 'IP_DELETE_LOCAL';

export function ipDeleteLocal(id) {
  return {
    type: IP_DELETE_LOCAL,
    payload: { id },
  };
}



