import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {included, input, menu, sip} from "../translate";
import {Helmet} from "react-helmet";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Cookies from "js-cookie";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDownWideShort,
    faArrowUpShortWide,
    faCircleMinus,
    faPenToSquare,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AddInclude from "../components/AddInclude";
import EditInclude from "../components/EditInclude";
import SearchIcon from "@mui/icons-material/Search";
import {includeDelete, includeRequest, includeUploadRequest} from "../store/actions/included";
import qs from "query-string";
import Utils from "../Utils";
import Api from "../Api";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import _ from 'lodash'
import {toast} from "react-toastify";

const titleArr = [
    sip.phone[+localStorage.getItem('atsLang') || 1],
    sip.note[+localStorage.getItem('atsLang') || 1],
    sip.out[+localStorage.getItem('atsLang') || 1],
    included.access[+localStorage.getItem('atsLang') || 1],
    included.mob[+localStorage.getItem('atsLang') || 1],
];
let descArr = []

function Included(props) {
    const [create, setCreate] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [edit, setEdit] = useState(0)
    const [copied, setCopied] = useState(false)
    const includeData = useSelector(state => state.included.includeData)
    const profile = useSelector(state => state.users.ursData)
    const page = useSelector(state => state.included.page)
    const count = useSelector(state => state.included.count)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("included", page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to delete?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите судалить?" :
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(includeDelete(id))
                await Api.allowedDelete(id)
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    const handleDeleteAll = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to delete included number with its SIP and Short?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите удалить еще и SIP и короткий номер ?" :
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել ներառված համարը SIP և կարճ համարի հետ մեկտեղ";

            if (confirm(text) === true) {
                await dispatch(includeDelete(id))
                await Api.allowedDeleteAll(id)
                toast.success('Success')
            }

        } catch (e) {
            console.log(e)
        }
    }, [])
    useEffect(() => {
        (async () => {
            await dispatch(includeRequest(Cookies.get("included") || query.limit || 10, query.page || 1, query.search || '', query.sortBy, query.sortOrder))
        })()
    }, [location.search, sortBy, sortOrder])
    useEffect(() => {
        if (!_.isEmpty(includeData)) {
            includeData?.map(s => {
                descArr.push({
                    number: s.phone,
                    notes: s.context,
                    out: s.sipdid,
                    line: s.codelist,
                    rec: s.phoneProvider?.name,
                })
            })
        }
    }, [descArr, includeData])

    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleSort = (column) => {
        query.sortBy = column
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            query.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setSortBy(column);
            setSortOrder('asc');
            query.sortOrder = 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }
    };

    const handleUpload = useCallback(async (ev) => {
        if(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'].includes(ev.target.files[0].type) ){
            await dispatch(includeUploadRequest(ev.target.files[0],Cookies.get("included") || query.limit || 10, query.page || 1, query.search || '', query.sortBy, query.sortOrder))
        }else{
            toast.error('It should be an exel file')
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    {menu.included[+localStorage.getItem('atsLang') || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                            {menu.included[+localStorage.getItem('atsLang') || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15, padding: '0 35px', height: 50}} align="right"
                                                   colSpan={6}>
                                            <span className="d-flex justify-content-end align-items-center">
                                                <label className="search">
                                                    <input value={query.search || ''} onChange={handleSearch}
                                                           placeholder={input.search[+localStorage.getItem('atsLang') || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                    <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                                {Cookies.get('viewer') && Cookies.get('original') && +profile.admingroup?  <label style={{marginLeft: 10}} className="add"><FileUploadIcon/>
                                                        <input
                                                            onChange={handleUpload}
                                                            type="file"
                                                            className="d-none"/>
                                                    </label>:null}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('phone')}
                                        >
                                            {sip.phone[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'phone' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('context')}
                                            align='center'
                                        >
                                            {sip.note[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'context' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('sipdid')}
                                            align='center'
                                        >
                                            {sip.out[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'sipdid' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('codelist')}
                                            align='center'
                                        >
                                            {included.access[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'codelist' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('provider')}
                                            align='center'
                                        >
                                            {included.mob[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'provider' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang') || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody id="dataBody">
                                    {includeData.length ? includeData.map(s => (
                                        <TableRow key={s?.id} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">
                                                {s?.phone}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.context}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.sipdid}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.codetypeResult?.map(m => <span key={m.id}> {m.later} </span>)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.phoneProvider?.name}
                                            </TableCell>
                                            <TableCell className="action" align="center">
                                                <span onClick={() => setEdit(s.id)} className="edit"><FontAwesomeIcon
                                                    icon={faPenToSquare}/></span>
                                                {Cookies.get('viewer') && Cookies.get('original') ?
                                                    <span onClick={() => handleDelete(s.id)}
                                                          className="delete"><FontAwesomeIcon
                                                        icon={faTrashCan}/></span> : null}
                                                {Cookies.get('viewer') && Cookies.get('original') ?
                                                    <span onClick={() => handleDeleteAll(s.id)}
                                                          className="delete"><FontAwesomeIcon
                                                        icon={faCircleMinus}/></span> : null}
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={6} sx={{fontSize: 13, width: 150}}>
                                            no data

                                        </TableCell>


                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}}
                                  onClick={() => Utils.dataToExel(titleArr, descArr)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}}
                                  onClick={() => Utils.dataToExel(titleArr, descArr)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100, {label: 'All', value: -1}]}
                                component="div"
                                count={+count}
                                rowsPerPage={Cookies.get("included") || query.limit || 10}
                                page={+page - 1}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev, newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>

                    </Paper>
                    {copied ? <div className="copied">Copied</div> : null}
                </div>
                {create ? <AddInclude open={create} setOpen={setCreate}/> : null}
                {edit ? <EditInclude open={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>
    );
}

export default Included;
