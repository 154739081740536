export const CALL_ROUT_REQUEST = 'CALL_ROUT_REQUEST';
export const CALL_ROUT_SUCCESS = 'CALL_ROUT_SUCCESS';
export const CALL_ROUT_FAIL = 'CALL_ROUT_FAIL';

export function callRoutRequest(limit,page, search) {
  return {
    type: CALL_ROUT_REQUEST,
    payload: {limit,page, search},
  };
}
export const CALL_ROUT_CREATE_REQUEST = 'CALL_ROUT_CREATE_REQUEST';
export const CALL_ROUT_CREATE_SUCCESS = 'CALL_ROUT_CREATE_SUCCESS';
export const CALL_ROUT_CREATE_FAIL = 'CALL_ROUT_CREATE_FAIL';

export function callRoutCreateRequest(limit,page, search,form) {
  return {
    type: CALL_ROUT_CREATE_REQUEST,
    payload: {limit,page, search,form},
  };
}
export const CALL_ROUT_EDIT_REQUEST = 'CALL_ROUT_EDIT_REQUEST';
export const CALL_ROUT_EDIT_SUCCESS = 'CALL_ROUT_EDIT_SUCCESS';
export const CALL_ROUT_EDIT_FAIL = 'CALL_ROUT_EDIT_FAIL';

export function callRoutEditRequest(limit,page,search,id,form) {
  return {
    type: CALL_ROUT_EDIT_REQUEST,
    payload: {limit,page, search,id,form},
  };
}
export const CALL_ROUT_ADD_LOCAL = 'CALL_ROUT_ADD_LOCAL';

export function callForwardLocal(data) {
  return {
    type: CALL_ROUT_ADD_LOCAL,
    payload: { data },
  };
}
export const CALL_ROUT_DELETE_LOCAL = 'CALL_ROUT_DELETE_LOCAL';

export function callForwardDeleteLocal(id) {
  return {
    type: CALL_ROUT_DELETE_LOCAL,
    payload: { id },
  };
}



