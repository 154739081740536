import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {input, ivr, menu, short, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpShortWide, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import AddIvr from "../components/AddIvr";
import EditIvr from "../components/EditIvr";
import SearchIcon from "@mui/icons-material/Search";
import {ivrDelete, ivrRequest} from "../store/actions/ivr";
import {fileRequest} from "../store/actions/file";
import qs from "query-string";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Utils from "../Utils";
import Api from "../Api";
import {shortNumberDelete} from "../store/actions/shortNumber";
import Cookies from "js-cookie";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function Ivr(props) {
    const ivrData = useSelector(state => state.ivr.ivrData)
    const page = useSelector(state => state.ivr.page)
    const count = useSelector(state => state.ivr.count)
    const [edit,setEdit] = useState(0)
    const [create,setCreate] = useState(false)
    const [isSearch,setIsSearch] = useState(false)
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [copied, setCopied] = useState(false)
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                if(typeof id === 'number'){
                    await Api.deleteIvr(id)
                }
                await dispatch(ivrDelete(id))
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    useEffect(() => {
        (async () => {
            await dispatch(ivrRequest(Cookies.get("ivr") ||query.limit || 10,query.page || 1 ,query.search || '',sortBy,sortOrder))
        })()
    },[location.search,sortBy,sortOrder])
    const handleSearch = useCallback((ev) => {
        query.search = ev
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleSort = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("ivr",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    return (
        <>
            <Helmet>
                <title>
                    {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                    <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                        {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]}
                                    </TableCell>
                                    <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end">
                                                <label className="search">
                                                    <input value={query.search || ''} onChange={(ev) => handleSearch(ev.target.value)} placeholder={input.search[+localStorage.getItem('atsLang')  || 1 ]}/>
                                                    <span className="search_cover" style={isSearch?{top:0}:{}} />
                                                </label>
                                                <span style={{margin:'0 10px'}} className="add" onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                    </TableCell>
                                </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('name')}
                                        >
                                            {ivr.name[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'name' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('virtual')}

                                        >
                                            {ivr.private[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'virtual' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('noaction')}

                                        >
                                            {short.no_answer[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'noaction' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ivrData.length ? ivrData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell align="center">
                                                <CheckCircleIcon style={{color:color,fontSize:18}} />
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.virtual}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.noaction}
                                            </TableCell>
                                            <TableCell className="action" align="center" sx={{display:"flex",justifyContent:'center'}}>
                                                <span onClick={() => setEdit(s.id)} className="edit"><FontAwesomeIcon
                                                    icon={faPenToSquare}/></span>
                                                <span onClick={() => handleDelete(s.id)} className="delete"><FontAwesomeIcon icon={faTrashCan}/></span>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                            no data

                                        </TableCell>
                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                ivr.name[+localStorage.getItem('atsLang')  || 1],
                                ivr.private[+localStorage.getItem('atsLang')  || 1],
                                short.no_answer[+localStorage.getItem('atsLang')  || 1],
                            ], ivrData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                ivr.name[+localStorage.getItem('atsLang')  || 1],
                                ivr.private[+localStorage.getItem('atsLang')  || 1],
                                short.no_answer[+localStorage.getItem('atsLang')  || 1],
                            ], ivrData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={count || 0}
                                rowsPerPage={Cookies.get("ivr") ||query.limit || 10}
                                page={page - 1 || 0}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev,newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>

                    </Paper>
                    {copied ? <div className="copied">Copied</div> : null}

                </div>
                {create ? <AddIvr open={create} setOpen={setCreate}/> : null}
                {edit ? <EditIvr open={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default Ivr;
