import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '210px',
};

function Telegram({open, setOpen,setForTelegram}) {
    const [number,setNumber] = useState('')
    const handleSubmit = useCallback(async () => {
        try{
            await Api.sipTelegramCreate({...open,phone:number})
            setOpen({pass:'',buddy:open.buddy,phone:number})
            setForTelegram(number)
        }catch (e) {

        }
    }, [number,open])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open.pass}
                onClose={() => {
                    setOpen({pass:'',buddy:open.buddy,phone:''})
                }}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open.pass}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Telegram
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen({pass:'',buddy:open.buddy,phone:''})
                            }}>
                                                         <CloseIcon/>

                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <label className="count" style={{width: '86%'}}>
                                <input value={number} onChange={(ev) => setNumber(ev.target.value)}/>
                            </label>
                            <div className="d-flex justify-content-center">
                                <button className="addBtnBig" onClick={handleSubmit}>
                                    {global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default Telegram;
