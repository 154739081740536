import React, {useCallback, useEffect, useRef, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import HistoryFilter from "../components/HistoryFilter";
import {Helmet} from "react-helmet";
import {history, historyMedia, menu, sip} from "../translate";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Graphics from "../components/Graphics";
import {useDispatch, useSelector} from "react-redux";
import {historyRequest} from "../store/actions/history";
import qs from "query-string";
import Api from "../Api";
import Utils from "../Utils";
import {REACT_APP_API_URL} from "../config";
import HistoryFilter1 from "../components/HistoryFilter1";
import AudioPlayer from "../components/AudioPlayer";
const {REACT_APP_SERVER} = process.env;
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Cookies from "js-cookie";
function History(props) {
    const [expanded, setExpanded] = useState(true)
    const [trackIndexSet, setTrackIndex] = useState(0)
    const chartRef = useRef(null);
    const historyData = useSelector(state => state.history.historyData)
    const duration = useSelector(state => state.history.duration)
    const historyStatus = useSelector(state => state.history.historyStatus)
    const count = useSelector(state => state.history.count)
    const page = useSelector(state => state.history.page)
    const location = useLocation()
    let track = []
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleLimit = useCallback(async (page) => {
        query.limit = page
        Cookies.set("history",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(historyRequest(Cookies.get("history") ||query.limit || 10, query.page || 1, {
            endTime:  query.endTime,
            startTime:  query.startTime,
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            line: query.line || '',
            direction:query.direction || '',
            period:query.period || ''
        }))
    }, [location.search])
    const handleChangePage = useCallback(async (page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(historyRequest(Cookies.get("history") ||query.limit || 10, query.page || 1, {
            endTime:  query.endTime,
            startTime:  query.startTime,
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            line: query.line || '',
            direction:query.direction || '',
            period:query.period || ''
        }))
    }, [location.search])
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            let yourDate = new Date()
            const offset = yourDate.getTimezoneOffset()
            yourDate = new Date(yourDate.getTime() - (offset*60*1000))
            await dispatch(historyRequest(Cookies.get("history") ||query.limit || 10, query.page || 1, {
                endTime:  query.endTime || yourDate.toISOString().split('T')[0],
                startTime:  query.startTime || yourDate.toISOString().split('T')[0],
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                line: query.line || '',
                direction:query.direction || '',
                period:query.period || ''
            }))
        })()
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {menu.history[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent m-0">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <Accordion expanded={expanded}>
                            <AccordionSummary
                                style={{
                                    padding: '0 30px',
                                    height: 80,
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                aria-expanded="true"
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                               <div className="w-100" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                       <h1 style={{fontSize: 20, margin: 0}}>
                                           <Link
                                               to="/history">{menu.history[+localStorage.getItem('atsLang')  || 1]}</Link>
                                       </h1>
                                       <div className="header_border" style={{height: 40, marginBottom: 15}}/>
                                       <h1 style={{fontSize: 20, margin: 0}}>
                                           <Link to="/graphic">Graphic</Link>
                                       </h1>
                                   </div>

                                   <span style={{display: 'flex', alignItems: 'center'}}>
                                    <CalendarMonthIcon style={{transition: 'transform 0s'}}
                                                       onClick={() => setExpanded(!expanded)}/>
                                </span>
                               </div>
                            </AccordionSummary>
                            <hr className="m-0"/>

                            <AccordionDetails>
                                {REACT_APP_SERVER === 'Beeline'?<HistoryFilter1/>:<HistoryFilter/>}
                            </AccordionDetails>
                        </Accordion>
                        <hr style={{borderColor: '#d4d4d4', margin: 0}}/>
                        {window.location.pathname === '/graphic' ?
                            <Graphics/>
                            :
                            <TableContainer>
                                <Table stickyHeader aria-label="table">
                                    <TableHead>
                                        <TableRow className="sip_header">
                                            { history.map((column, i) => (
                                                <TableCell
                                                    style={i === 0 || i===history.length-1?{padding:'5px 13px'}:{padding:5}}
                                                    key={i}
                                                    align="center"
                                                >
                                                    {column[+localStorage.getItem('atsLang')  || 1]}
                                                </TableCell>
                                            )) }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={_.isEmpty(historyData?.response?.docs)?{height:'calc(100vh - 600px)'}:{}}>
                                        {!_.isEmpty(historyData?.response?.docs)?historyData?.response?.docs?.map((row,_) => {
                                            if(row.isAudio && !Cookies.get('viewer')){
                                                track.push({src:REACT_APP_API_URL + '/history/audio?file='+row.uniqueid,id:row.uniqueid,
                                                type:(row.line && row.line !== 'null') || row.ext_num?'Outgoing':row.in_num?'Incoming' : ''})
                                            }
                                            return (
                                                <TableRow className="sip_num" hover role="checkbox" tabIndex={-1}
                                                          key={row.id}>

                                                    <TableCell align="center" >
                                                        {(page - 1 === 0 ? '' : (page - 1).toString()) + (_ + 1)}
                                                    </TableCell><TableCell  style={{width:100 / 8 + '%',fontSize:10}} align="center">
                                                        {Utils.newFormat(row.start)} - {Utils.newFormat(row.endz)}
                                                    </TableCell>
                                                    <TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.extension ? row.extension : ''}
                                                    </TableCell>
                                                    <TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {Utils.secToHours(row.duration ? row.duration : 0)}
                                                    </TableCell><TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.line && row.line !== 'null'?row.line:row.ext_num?row.ext_num[0]:''}
                                                    </TableCell><TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.in_num?row.in_num : ''}
                                                    </TableCell><TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.virtual?row.virtual : ''}
                                                    </TableCell><TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.disposition?row.disposition: ''}
                                                    </TableCell><TableCell style={{width:100 / 8 + '%'}} align="center">
                                                        {row.destination?row.destination: ''}
                                                    </TableCell>
                                                    <TableCell align="center" style={{width:100 / 8 + '%',cursor:'pointer'}}>
                                                        {Cookies.get('viewer')?<PlayCircleIcon style={{color:'#ccc'}} />:<PlayCircleIcon onClick={() => {
                                                            setTrackIndex(track.findIndex(s => s.id === row.uniqueid))
                                                        }} style={!row.isAudio?{color:'#ccc'}:{color:'blue'}} />}
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        }): <TableRow className="sip_num" role="checkbox" tabIndex={-1}>
                                            <TableCell colSpan={10} align="center">
                                                {historyStatus === "request"?'Loading...':'No data'}
                                            </TableCell>
                                        </TableRow>}
                                        <TableRow className="sip_num" role="checkbox" tabIndex={-1}>
                                            <TableCell colSpan={2} align="right">
                                                Total
                                            </TableCell>
                                            <TableCell colSpan={4} align="center">
                                                {Utils.secToHours(duration ? duration : 0)}
                                            </TableCell>
                                            <TableCell colSpan={2} align="left">
                                                Total call count
                                            </TableCell>
                                            <TableCell colSpan={4} align="center">
                                                {count || 0}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        }
                        {window.location.pathname === '/graphic' ?null:<div className="foot_footer d-flex align-items-center justify-content-end">
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={count || 1}
                                rowsPerPage={Cookies.get("history") ||query.limit || 10}
                                page={page - 1 || 0}
                                onPageChange={async (event, newPage) => await handleChangePage(newPage)}
                                onRowsPerPageChange={async(ev,newPage) => await handleLimit(newPage.props.value)}
                            />
                        </div>}

                    </Paper>
                </div>
                {track.length?<AudioPlayer track={track} trackIndexSet={trackIndexSet} setTrackIndex={setTrackIndex}/>:null}
            </Wrapper>

        </>
    );
}
// SOLR_URL +

export default History;
