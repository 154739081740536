import React, {useCallback, useState} from 'react';
import Api from "../Api";
import {toast} from "react-toastify";
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {global} from "../translate";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '300px',
    overflow: 'auto',
};
function UrsAdd({open, setOpen,}) {
    const [number, setNumber] = useState('+374')

    const handleSubmit = useCallback(async () => {
        try{
            await Api.register(number,location.pathname)
            setOpen(0)
        }catch (e) {toast.error(e.response.data.message)}
    }, [open, number])

    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => setOpen(0)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Create
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen(0)}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row w-100">
                                <input className="create_input" value={number} onChange={(ev) => setNumber(ev.target.value)}/>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button onClick={handleSubmit} className="addBtn">
                                    {global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );

}

export default UrsAdd;
