import {URS_FAIL, URS_REQUEST, URS_SUCCESS, USERS_FAIL, USERS_REQUEST, USERS_SUCCESS,} from '../actions/users';


const initialState = {
    userStatus: 'request',
    userData: {},
    countData: {},
    ursData: {},
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case USERS_REQUEST: {
            return {
                ...state,
                userStatus: 'request',
                userData: {},
            };
        }
        case USERS_SUCCESS: {
            return {
                ...state,
                userStatus: 'success',
                userData: {...action.payload.data.user,clientIp:action.payload.data.clientIp},
                countData: action.payload.data.count,
            };
        }
        case USERS_FAIL: {
            return {
                ...state,
                userStatus: 'fail',
                userData: action.payload.data,
            };
        }
        case URS_REQUEST: {
            return {
                ...state,
                userStatus: 'request',
                ursData: {},
            };
        }
        case URS_SUCCESS: {
            return {
                ...state,
                userStatus: 'success',
                ursData: action.payload.data.user,
            };
        }
        case URS_FAIL: {
            return {
                ...state,
                userStatus: 'fail',
                ursData: action.payload.data,
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
