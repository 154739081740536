import {call, put, takeLatest} from 'redux-saga/effects';
import {
    LOGS_FAIL,
    LOGS_REQUEST,
    LOGS_SINGLE_REQUEST, LOGS_SUCCESS,
} from '../actions/logs';
import Api from '../../Api';
import {toast} from "react-toastify";



function* handleLogRequest(action) {
    try {
        const {limit,page,search} = action.payload

        const {data} = yield call(Api.getLogs, limit,page,search);
        yield put({
            type: LOGS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)
        console.log(e)
        yield put({
            type: LOGS_FAIL,
            payload: {error: e.response},
        });
    }
}

// function* handleHistoryExelRequest(action) {
//     try {
//         const {rowsPerPage,page,search} = action.payload
//
//         yield call(Api.historyExel, rowsPerPage,page,search);
//         yield put({
//             type: HISTORY_EXEL_SUCCESS,
//             payload: {},
//         });
//     } catch (e) {
//         toast.error(e.response.data.message)
//
//         yield put({
//             type: HISTORY_EXEL_FAIL,
//             payload: {error: e.response},
//         });
//     }
// }



export default function* watcher() {
    yield takeLatest(LOGS_REQUEST, handleLogRequest);
    // yield takeLatest(HISTORY_EXEL_REQUEST, handleHistoryExelRequest);
}
