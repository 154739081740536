import React, {useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import Loader from "../components/Loader";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {input} from "../translate";
import {TableFooter} from "@mui/material";
const {REACT_APP_SERVER} = process.env;

let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function Zoho(props) {
    const [zoho,setZoho]=useState([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        (async () => {
           const {data} = await Api.getZohoUsers(10,50);
           setZoho(data)
        })()
    },[])
    return (
        <Wrapper>
            {loading ? <div className="loaderContainer"><Loader/></div> :
                <div className="tableParent" id="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                            Users of Zoho
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell align='center'>
                                            ID
                                        </TableCell>
                                        <TableCell align='center'>
                                            Full name
                                        </TableCell>
                                        <TableCell align='center'>
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Register date
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Selected SIP
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody id="dataBody">
                                    {zoho.length ? zoho.map((s, _) => (
                                        <TableRow key={_} hover style={{cursor:'pointer'}}  role="checkbox" tabIndex={-1}>
                                            <TableCell align="center" onClick={() => null}>
                                                {s?.status === 'active' ? <CheckCircleIcon style={{color: color}}/> : null}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.zuid}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.full_name}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.email}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.type__s}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {new Date(s.created_time).toDateString()}
                                            </TableCell>
                                            <TableCell align="center">
                                                {/*<select value={s?.sip} className="crm_select"*/}
                                                {/*        onChange={(ev) => null}>*/}
                                                {/*    <option*/}
                                                {/*        value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>*/}


                                                {/*</select>*/}
                                            </TableCell>

                                            <TableCell>
                                                <button  className="addBtn">
                                                    Save
                                                </button>
                                            </TableCell>
                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            no data

                                        </TableCell>


                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            <div style={{margin:10}} className="crm_save_btn"/>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>}
        </Wrapper>
    );
}

export default Zoho;
