import {
    SHORT_CREATE_SUCCESS, SHORT_EDIT_SUCCESS,
    SHORT_NUMBER_ADD_LOCAL,
    SHORT_NUMBER_DELETE_LOCAL,
    SHORT_NUMBER_FAIL,
    SHORT_NUMBER_REQUEST,
    SHORT_NUMBER_SUCCESS
} from '../actions/shortNumber';


const initialState = {
    shortNumberData: [],
    shortNumberStatus: '',
    page:1,
    count:0,
    totalPages: 1
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SHORT_NUMBER_REQUEST: {
            return {
                ...state,
                shortNumberStatus: 'request',
                shortNumberData: [],
            };
        }
        case SHORT_NUMBER_SUCCESS: {
            return {
                ...state,
                shortNumberStatus: 'success',
                shortNumberData: action.payload.data.short,
                page:action.payload.data.page,
                count:action.payload.data.count,
            };
        }
        case SHORT_CREATE_SUCCESS: {
            return {
                ...state,
                shortNumberStatus: 'success',
                shortNumberData: action.payload.data.short,
                page:action.payload.data.page,
                count:action.payload.data.count,
            };
        }
        case SHORT_EDIT_SUCCESS: {
            return {
                ...state,
                shortNumberStatus: 'success',
                shortNumberData: action.payload.data.short,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }

        case SHORT_NUMBER_FAIL: {
            return {
                ...state,
                shortNumberStatus: 'fail',
            };
        }
        case SHORT_NUMBER_ADD_LOCAL: {
            return {
                ...state,
                shortNumberData: [...state.shortNumberData, {...action.payload.data,id:new Date()}],
            };
        }
        case SHORT_NUMBER_DELETE_LOCAL: {
            return {
                ...state,
                shortNumberData: state.shortNumberData.filter(s => s.id !== action.payload.id),
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
