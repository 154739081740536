import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 350 ? 350 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '220px',
    overflow: 'hidden'
};

function TelegramCode({setIsEmail,open, setOpen, setFilter,session,setSession,storage,setStorage}) {
    const [code,setCode] = useState('')
    const [loader,setLoader] = useState(false)
    const handleSubmit = useCallback(async () => {
        try {
            setLoader(true)
            const {data} = await Api.telegRegVerify(open,code,session,storage)
            if(data.status?.next){
                setIsEmail(open)
            }else{
                setFilter((prev => ({
                    ...prev,
                    username: true,
                })))
                setOpen('')
                setSession('')
                setStorage('')
                setLoader(false)
            }

        }catch (e) {
            console.log(e)
        }
    }, [code,open,session,storage])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => {
                    setOpen('')
                }}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Enter code
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen('')
                            }}>
                                                         <CloseIcon/>

                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <label className="count" style={{width: '86%'}}>
                                <input value={code} onChange={(ev) => setCode(ev.target.value)}/>
                            </label>
                            <div className="d-flex justify-content-center">
                                <button style={{padding: '6px 17px'}} className="addBtnBig" onClick={handleSubmit}>
                                    {loader?'Loading...':global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default TelegramCode;
