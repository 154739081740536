import React from 'react';
import {Helmet} from "react-helmet";

function NotFound(props) {
    return (
        <>
            <Helmet>
                <title>Not found</title>
            </Helmet>
            <div className="error__block d-flex flex-column align-items-center justify-content-center">
                <div className="Error404">404</div>
                <div className="ErrorMessage">Oops! The page you&apos;re looking for is not found</div>
            </div>
        </>
    );
}

export default NotFound;
