import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {auto, input, menu, sip,} from "../translate";
import {useDispatch} from "react-redux";
import {blockedCreateRequest, blockedLocal} from "../store/actions/blocked";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerWidth > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function AddBlocked({open, setOpen}) {
    const [form, setForm] = useState({
        phone: '',
        notes: '',
    })

    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])

    const handleAdd = useCallback(async () => {
        await dispatch(blockedCreateRequest(10, query.page || 1, query.search || '',form))
        setOpen(false)
    }, [form,location.search])


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.block[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                        <CloseIcon/>

                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <label style={{margin: '20px 0'}} className="col-md-12 count">
                            <span>
                               {auto.phone[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.phone}
                                   onChange={(ev) => handleChange('phone', ev.target.value)}/>
                        </label>
                        <label style={{margin: '20px 0'}} className="col-md-12 count">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.notes}
                                   onChange={(ev) => handleChange('notes', ev.target.value)}/>
                        </label>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtnBig">
                            {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddBlocked;
