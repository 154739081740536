import {
    HISTORY_FAIL,
    HISTORY_REQUEST,
    HISTORY_SUCCESS
} from '../actions/history';

const initialState = {
    historyData: [],
    historyStatus:'',
    duration:0,
    page:1,
    count:0,
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HISTORY_REQUEST: {
            return {
                ...state,
                historyStatus: 'request',
                historyData: [],
            };
        }
        case HISTORY_SUCCESS: {
            return {
                ...state,
                historyStatus: 'success',
                historyData: action.payload.data.history,
                page:action.payload.data.page,
                count:action.payload.data.history.response.numFound,
                duration:action.payload.data.history.stats.stats_fields.duration.sum,
            };
        }
        case HISTORY_FAIL: {
            return {
                ...state,
                historyStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
