import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input} from "../translate";
import {useSelector} from "react-redux";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '97vh'
};

function EditShortFunction({open, setOpen, all,have}) {
    const [form, setForm] = useState('')
    const userData = useSelector((state) => state.users.userData)
    useEffect(() => {
        setForm(have?all.find(s => s.function_code === open).day_count:'')
    },[all,open])
    const handleAdd = useCallback(async () => {
        try {
            let formData = {}
            formData = {
                familiar_client: all[0].day_count,
                ending_call: all[1].day_count,
                voice_mail: all[2].day_count
            }
            all.map(s => {
                if (+open === 10999) {
                    formData = {...formData, 'familiar_client': form}
                } else if (+open === 10888) {
                    formData = {...formData, 'ending_call': form}
                } else {
                    formData = {...formData, 'voice_mail': form}
                }
            })
            formData = {...formData, member: userData.id}
            await Api.shortFunctionEdit(formData)
            window.location.reload()
            setOpen('')
        }catch (e) {

        }
    }, [form, userData, all])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={!!open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={!!open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Short function
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <label style={{margin: '20px 0'}} className="col-md-9 count">
                            <span>
                               {global.count[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                            <input value={form} onChange={(ev) => setForm(ev.target.value)}/>
                        </label>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtn">
                            {global.edit[+localStorage.getItem('atsLang')  || 1]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditShortFunction;
