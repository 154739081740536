export const IVR_REQUEST = 'IVR_REQUEST';
export const IVR_SUCCESS = 'IVR_SUCCESS';
export const IVR_FAIL = 'IVR_FAIL';

export function ivrRequest(limit,page,search,sort,sortBy) {
    return {
        type: IVR_REQUEST,
        payload: {limit,page,search,sort,sortBy },
    };
}
export const IVR_CREATE_REQUEST = 'IVR_CREATE_REQUEST';
export const IVR_CREATE_SUCCESS = 'IVR_CREATE_SUCCESS';
export const IVR_CREATE_FAIL = 'IVR_CREATE_FAIL';

export function ivrCreateRequest(limit,page,search,form) {
    return {
        type: IVR_CREATE_REQUEST,
        payload: {limit,page,search,form },
    };
}
export const IVR_EDIT_REQUEST = 'IVR_EDIT_REQUEST';
export const IVR_EDIT_SUCCESS = 'IVR_EDIT_SUCCESS';
export const IVR_EDIT_FAIL = 'IVR_EDIT_FAIL';

export function ivrEditRequest(limit,page,search,id,form) {
    return {
        type: IVR_EDIT_REQUEST,
        payload: {limit,page,search,id,form },
    };
}

export const IVR_ADD_LOCAL = 'IVR_ADD_LOCAL';

export function ivrAdd(data) {
    return {
        type: IVR_ADD_LOCAL,
        payload: {data},
    };
}
export const IVR_DELETE_LOCAL = 'IVR_DELETE_LOCAL';

export function ivrDelete(id) {
    return {
        type: IVR_DELETE_LOCAL,
        payload: {id},
    };
}




