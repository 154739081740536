import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import $ from "jquery";
import {historyHeader, input, lang} from "../translate";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 800 ? 800 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: window.innerHeight > 565 ? 565 : '97vh',
    overflow: 'auto',
};

function UrsVoip({open, setOpen,}) {
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [data, setData] = useState({})
    const dateInputStartRef = useRef(null);
    const dateInputEndRef = useRef(null);
    useEffect(() => {
        $.datepicker.setDefaults($.datepicker.regional[lang[+localStorage.getItem('atsLang')]]);

        $(dateInputEndRef.current).datepicker({
            dateFormat: 'yy-mm-dd',
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });
        $(dateInputStartRef.current).datepicker({
            dateFormat: 'yy-mm-dd',
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });

        $(dateInputStartRef.current).on('change', function () {
            setStart($(this).val());
        });
        $(dateInputEndRef.current).on('change', function () {
            setEnd($(this).val());
        });

    }, []);

    const handleSubmit = useCallback(async () => {
        try{
            const {data} = await Api.getVoip(start,end)
            setData(data)
        }catch (e) {

        }
    },[start,end])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => setOpen('')}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Report
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen('')}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row history_page w-100">
                                <div className="col-md-4">
                                    <span>{historyHeader.start[+localStorage.getItem('atsLang')  || 1]}</span>
                                    <input
                                        ref={dateInputStartRef}
                                        type="text"
                                        value={start}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <span>{historyHeader.end[+localStorage.getItem('atsLang')  || 1]}</span>

                                    <input
                                        ref={dateInputEndRef}
                                        type="text"
                                        value={end}
                                    />
                                </div>
                                <div className="d-flex justify-content-start">
                                    <button onClick={handleSubmit} style={{margin: '25px 0',
                                        padding: '5px 25px',}} className="addBtn">Search</button>
                                </div>

                            </div>
                            <div className="w-100">
                                <div>{data?.grupstr}</div>
                                <div>{data?.str}</div>
                                <div className="taskTable">
                                    <table>
                                        <tr>
                                            <th>Num</th>
                                            <th>User</th>
                                            <th>Gumar</th>
                                            <th>Date(from-to)</th>
                                        </tr>
                                        {data?.voip?.map(l => (
                                            <tr key={l.id}>
                                                <th>Num</th>
                                                <th>User</th>
                                                <th>Gumar</th>
                                                <th>Date(from-to)</th>
                                            </tr>
                                        ))}
                                    </table>

                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default UrsVoip;
