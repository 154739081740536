import { takeLatest, call, put } from 'redux-saga/effects';
import {
    SHORT_NUMBER_REQUEST,
    SHORT_NUMBER_SUCCESS,
    SHORT_NUMBER_FAIL,
    SHORT_CREATE_REQUEST,
    SHORT_CREATE_SUCCESS,
    SHORT_CREATE_FAIL,
    SHORT_EDIT_REQUEST,
    SHORT_EDIT_SUCCESS, SHORT_EDIT_FAIL,
} from '../actions/shortNumber';
import Api from '../../Api';
import {toast} from "react-toastify";
import {SIP_CREATE_FAIL, SIP_CREATE_SUCCESS} from "../actions/sip";

function* handleShort(action) {
    try {
        const {limit, page, search,sort,sortBy} = action.payload
        const { data } = yield call(Api.short,limit, page, search,sort,sortBy);
        yield put({
            type: SHORT_NUMBER_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SHORT_NUMBER_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleShortCreate(action) {
    try {
        const {limit,page,search,form,sort,sortBy} = action.payload;
        const {data} = yield call(Api.shortCreate,limit,page,search,form,sort,sortBy);

        toast.success('Short number is successfully created')

        yield put({
            type: SHORT_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SHORT_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleShortEdit(action) {
    try {
        const {limit,page,search,id,form,sort,sortBy} = action.payload;
        const {data} = yield call(Api.shortEdit,limit,page,search,id,form,sort,sortBy);

        toast.success('Short number is successfully edited')

        yield put({
            type: SHORT_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SHORT_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}




export default function* watcher() {
    yield takeLatest(SHORT_NUMBER_REQUEST, handleShort);
    yield takeLatest(SHORT_CREATE_REQUEST, handleShortCreate);
    yield takeLatest(SHORT_EDIT_REQUEST, handleShortEdit);
}
