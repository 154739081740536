import { takeLatest, call, put } from 'redux-saga/effects';
import {
    CRM_REQUEST,
    CRM_SUCCESS,
    CRM_FAIL,
    CRM_CREATE_SUCCESS,
    CRM_CREATE_FAIL,
    CRM_CREATE_REQUEST,
    CRM_SINGLE_REQUEST,
    CRM_SINGLE_SUCCESS,
    CRM_SINGLE_FAIL,
} from '../actions/crm';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleCRM() {
    try {
        const { data } = yield call(Api.getCRM,);
        yield put({
            type: CRM_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CRM_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleCreateCrm(action) {
    try {
        const { data } = yield call(Api.crmCreate,action.payload.form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: CRM_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CRM_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSingleCrm(action) {
    try {
        const { data } = yield call(Api.crmSingle,action.payload.id);
        yield put({
            type: CRM_SINGLE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CRM_SINGLE_FAIL,
            payload: { error: e.response },
        });
    }
}




export default function* watcher() {
    yield takeLatest(CRM_REQUEST, handleCRM);
    yield takeLatest(CRM_CREATE_REQUEST, handleCreateCrm);
    yield takeLatest(CRM_SINGLE_REQUEST, handleSingleCrm);

}
