import {
    AUTO_CALL_ADD_LOCAL,
    AUTO_CALL_CREATE_SUCCESS,
    AUTO_CALL_DELETE_LOCAL,
    AUTO_CALL_FAIL, AUTO_CALL_STATUS_SUCCESS,
    AUTO_CALL_SUCCESS,
} from '../actions/autoCall';

const initialState = {
    autoCallStatus: '',
    autoCallData: [],
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case AUTO_CALL_SUCCESS: {
            return {
                ...state,
                autoCallStatus: 'ok',
                autoCallData: action.payload.data.auto
            };
        }
        case AUTO_CALL_STATUS_SUCCESS:
        case AUTO_CALL_CREATE_SUCCESS: {
            return {
                ...state,
                autoCallStatus: 'ok',
                autoCallData: action.payload.data.auto
            };
        }
        case AUTO_CALL_FAIL: {
            return {
                ...state,
                autoCallStatus: 'fail',
            };
        }
        case AUTO_CALL_ADD_LOCAL: {
            return {
                ...state,
                autoCallStatus: 'fail',
                autoCallData: [...state.autoCallData, {...action.payload.data, id: new Date()},]
            };
        }
        case AUTO_CALL_DELETE_LOCAL: {
            return {
                ...state,
                autoCallStatus: 'fail',
                autoCallData: state.autoCallData.filter(c => c.id !== action.payload.id)
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
