export const SIP_REQUEST = 'SIP_REQUEST';
export const SIP_SUCCESS = 'SIP_SUCCESS';
export const SIP_FAIL = 'SIP_FAIL';
export function sipRequest(limit,page, search,sortBy,sortOrder) {
    return {
        type: SIP_REQUEST,
        payload: {limit,page, search,sortBy,sortOrder},
    };
}

export const SIP_PROVIDER_REQUEST = 'SIP_PROVIDER_REQUEST';
export const SIP_PROVIDER_SUCCESS = 'SIP_PROVIDER_SUCCESS';
export const SIP_PROVIDER_FAIL = 'SIP_PROVIDER_FAIL';
export function sipProviderRequest(search,limit,page) {
    return {
        type: SIP_PROVIDER_REQUEST,
        payload: {search,limit,page},
    };
}

export const SIP_LOCAL_ADD = 'SIP_LOCAL_ADD';
export function sipLocalRequest(data) {
    return {
        type: SIP_LOCAL_ADD,
        payload: {data},
    };
}

export const SIP_PROVIDER_LOCAL_ADD = 'SIP_PROVIDER_LOCAL_ADD';
export function sipProviderLocalRequest(data) {
    return {
        type: SIP_PROVIDER_LOCAL_ADD,
        payload: {data},
    };
}

export const SIP_LOCAL_DELETE = 'SIP_LOCAL_DELETE';
export function sipLocalDelete(id) {
    return {
        type: SIP_LOCAL_DELETE,
        payload: {id},
    };
}

export const SIP_PROVIDER_LOCAL_DELETE = 'SIP_PROVIDER_LOCAL_DELETE';
export function sipProviderLocalDelete(id) {
    return {
        type: SIP_PROVIDER_LOCAL_DELETE,
        payload: {id},
    };
}


export const SIP_SINGLE_REQUEST = 'SIP_SINGLE_REQUEST';
export const SIP_SINGLE_SUCCESS = 'SIP_SINGLE_SUCCESS';
export const SIP_SINGLE_FAIL = 'SIP_SINGLE_FAIL';

export function sipSingleRequest(phone) {
    return {
        type: SIP_SINGLE_REQUEST,
        payload: {phone},
    };
}


export const SIP_EDIT_REQUEST = 'SIP_EDIT_REQUEST';
export const SIP_EDIT_SUCCESS = 'SIP_EDIT_SUCCESS';
export const SIP_EDIT_FAIL = 'SIP_EDIT_FAIL';

export function sipEditRequest(id, formData, limit, page, search,link,sort, sortBy) {
    return {
        type: SIP_EDIT_REQUEST,
        payload: {id, formData,limit, page, search,link,sort, sortBy},
    };
}

export const SIP_DELETE_REQUEST = 'SIP_DELETE_REQUEST';
export const SIP_DELETE_SUCCESS = 'SIP_DELETE_SUCCESS';
export const SIP_DELETE_FAIL = 'SIP_DELETE_FAIL';

export function sipDeleteRequest(form,link) {
    return {
        type: SIP_DELETE_REQUEST,
        payload: {form,link},
    };
}
export const SIP_CREATE_REQUEST = 'SIP_CREATE_REQUEST';
export const SIP_CREATE_SUCCESS = 'SIP_CREATE_SUCCESS';
export const SIP_CREATE_FAIL = 'SIP_CREATE_FAIL';

export function sipCreateRequest(buddy,token,issip,limit,page,search,lang,sort, sortBy) {
    return {
        type: SIP_CREATE_REQUEST,
        payload: {buddy,token,issip,limit,page,search,lang,sort, sortBy},
    };
}

export const SIP_PROVIDER_CREATE_REQUEST = 'SIP_PROVIDER_CREATE_REQUEST';
export const SIP_PROVIDER_CREATE_SUCCESS = 'SIP_PROVIDER_CREATE_SUCCESS';
export const SIP_PROVIDER_CREATE_FAIL = 'SIP_PROVIDER_CREATE_FAIL';

export function sipProviderCreateRequest(form,limit,page) {
    return {
        type: SIP_PROVIDER_CREATE_REQUEST,
        payload: {form,limit,page},
    };
}
export const SIP_PROVIDER_EDIT_REQUEST = 'SIP_PROVIDER_EDIT_REQUEST';
export const SIP_PROVIDER_EDIT_SUCCESS = 'SIP_PROVIDER_EDIT_SUCCESS';
export const SIP_PROVIDER_EDIT_FAIL = 'SIP_PROVIDER_EDIT_FAIL';

export function sipProviderEditRequest(form,limit,page) {
    return {
        type: SIP_PROVIDER_EDIT_REQUEST,
        payload: {form,limit,page},
    };
}


export const CRM_SINGLE = 'CRM_SINGLE';

export function crmSingleRequest(data) {
    return {
        type: CRM_SINGLE,
        payload: {data},
    };
}



