import {call, put, takeLatest} from 'redux-saga/effects';
import {
    HISTORY_EXEL_FAIL, HISTORY_EXEL_REQUEST,
    HISTORY_EXEL_SUCCESS,
    HISTORY_FAIL, HISTORY_REQUEST,
    HISTORY_SUCCESS,
} from '../actions/history';
import Api from '../../Api';
import {toast} from "react-toastify";



function* handleHistoryRequest(action) {
    try {
        const {rowsPerPage,page,search} = action.payload

        const {data} = yield call(Api.history, rowsPerPage,page,search);
        yield put({
            type: HISTORY_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        // toast.error(e.response.data.message)
        console.log(e)
        yield put({
            type: HISTORY_FAIL,
            payload: {error: e.response},
        });
    }
}

// function* handleHistoryExelRequest(action) {
//     try {
//         const {rowsPerPage,page,search} = action.payload
//
//         yield call(Api.historyExel, rowsPerPage,page,search);
//         yield put({
//             type: HISTORY_EXEL_SUCCESS,
//             payload: {},
//         });
//     } catch (e) {
//         toast.error(e.response.data.message)
//
//         yield put({
//             type: HISTORY_EXEL_FAIL,
//             payload: {error: e.response},
//         });
//     }
// }



export default function* watcher() {
    yield takeLatest(HISTORY_REQUEST, handleHistoryRequest);
    // yield takeLatest(HISTORY_EXEL_REQUEST, handleHistoryExelRequest);
}
