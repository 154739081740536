export const INCLUDE_REQUEST = 'INCLUDE_REQUEST';
export const INCLUDE_SUCCESS = 'INCLUDE_SUCCESS';
export const INCLUDE_FAIL = 'INCLUDE_FAIL';

export function includeRequest(limit,page,search,sort,sortBy) {
    return {
        type: INCLUDE_REQUEST,
        payload: {limit,page,search,sort,sortBy },
    };
}

export const INCLUDE_CREATE_REQUEST = 'INCLUDE_CREATE_REQUEST';
export const INCLUDE_CREATE_SUCCESS = 'INCLUDE_CREATE_SUCCESS';
export const INCLUDE_CREATE_FAIL = 'INCLUDE_CREATE_FAIL';

export function includeCreateRequest(limit,page,search,form,sort,sortBy) {
    return {
        type: INCLUDE_CREATE_REQUEST,
        payload: {limit,page,search,form,sort,sortBy },
    };
}
export const INCLUDE_UPLOAD_REQUEST = 'INCLUDE_UPLOAD_REQUEST';
export const INCLUDE_UPLOAD_SUCCESS = 'INCLUDE_UPLOAD_SUCCESS';
export const INCLUDE_UPLOAD_FAIL = 'INCLUDE_UPLOAD_FAIL';

export function includeUploadRequest(form,limit,page,search,sort,sortBy) {
    return {
        type: INCLUDE_UPLOAD_REQUEST,
        payload: {limit,page,search,form,sort,sortBy },
    };
}
export const INCLUDE_EDIT_REQUEST = 'INCLUDE_EDIT_REQUEST';
export const INCLUDE_EDIT_SUCCESS = 'INCLUDE_EDIT_SUCCESS';
export const INCLUDE_EDIT_FAIL = 'INCLUDE_EDIT_FAIL';

export function includeEditRequest(limit,page,search,form,sort,sortBy) {
    return {
        type: INCLUDE_EDIT_REQUEST,
        payload: {limit,page,search,form,sort,sortBy },
    };
}

export const PROVIDER_REQUEST = 'PROVIDER_REQUEST';
export const PROVIDER_SUCCESS = 'PROVIDER_SUCCESS';
export const PROVIDER_FAIL = 'PROVIDER_FAIL';

export function providerRequest() {
    return {
        type: PROVIDER_REQUEST,
        payload: {},
    };
}

export const INCLUDE_ADD_LOCAL = 'INCLUDE_ADD_LOCAL';

export function include(data) {
    return {
        type: INCLUDE_ADD_LOCAL,
        payload: {data},
    };
}

export const INCLUDE_DELETE_LOCAL = 'INCLUDE_DELETE_LOCAL';

export function includeDelete(id) {
    return {
        type: INCLUDE_DELETE_LOCAL,
        payload: {id},
    };
}
