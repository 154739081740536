import {call, put, takeLatest} from 'redux-saga/effects';
import {
    FORWARD_CREATE_FAIL, FORWARD_CREATE_REQUEST,
    FORWARD_CREATE_SUCCESS, FORWARD_EDIT_FAIL, FORWARD_EDIT_REQUEST, FORWARD_EDIT_SUCCESS,
    FORWARD_FAIL,
    FORWARD_REQUEST,
    FORWARD_SUCCESS,
} from '../actions/forward';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleForwardRequest(action) {
    try {
        const {limit,page,search} = action.payload;
        const {data} = yield call(Api.getForward, limit,page,search);
        yield put({
            type: FORWARD_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FORWARD_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleForwardCreateRequest(action) {
    try {
        const {limit,page,search,form} = action.payload;
        const {data} = yield call(Api.createForward, limit,page,search,form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: FORWARD_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FORWARD_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleForwardEditRequest(action) {
    try {
        const {limit,page,search,form} = action.payload;
        const {data} = yield call(Api.editForward, limit,page,search,form);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: FORWARD_EDIT_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: FORWARD_EDIT_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(FORWARD_REQUEST, handleForwardRequest);
    yield takeLatest(FORWARD_CREATE_REQUEST, handleForwardCreateRequest);
    yield takeLatest(FORWARD_EDIT_REQUEST, handleForwardEditRequest);

}
