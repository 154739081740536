import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { global, short, sip, weekDays, input, ivr} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {ivrAdd, ivrEditRequest} from "../store/actions/ivr";
import Api from "../Api";
import {fileRequest} from "../store/actions/file";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import Select from "react-select";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import {closestCorners, DndContext} from "@dnd-kit/core";
import IvrLine from "./IvrLine";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";

const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 830 ? 830 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerHeight > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function EditIvr({open, setOpen}) {
    const [form, setForm] = useState({
        ivr: '',
        private_num: '',
        no_answer: '',
        time: []
    })

    const [graphic, setGraphic] = useState({
        num: '',
        notes:'',
        weekStart: weekDays[1][+localStorage.getItem('atsLang') || 1],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang') || 1],
        timeStart: '00:00',
        timeEnd: '23:59',
        rx: 0,
        tx: 0,
    })
    const fileData = useSelector(state => state.file.fileData)
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        if(name === 'sec'){
            if(+ev > 60){
                setForm((prevState) => ({
                    ...prevState,
                    sec: 60
                }))
            }else{
                setForm((prevState) => ({
                    ...prevState,
                    sec: ev
                }))
            }
        }else{
            setForm((prevState) => ({
                ...prevState,
                [name]: ev
            }))
        }

    }, [])
    const handleTimeChange = useCallback((name, ev,notes) => {
        if (name === 'timeStart' || name === 'timeEnd') {
            const formattedTime = ev.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                second: undefined,
            });
            setGraphic((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }  else if (name === 'num') {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
                notes: notes || ''
            }))
        }else {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev
            }))
        }
    }, [])

    const handleAdd = useCallback(async () => {
        await dispatch(ivrEditRequest(Cookies.get("ivr") ||query.limit || 10,query.page || 1,query.search || '',open,form))
        setOpen(false)
    }, [form,open,location.search])
    const handleAddGraphic = useCallback(async () => {
        setForm((prev) => ({
            ...prev,
            time: [...prev.time, {...graphic,id:new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        try{
            setForm((prev) => ({
                ...prev,
                time: prev.time.filter(t => t.id !== id)
            }))
        }catch (e) {

        }
    }, [graphic])

    useEffect(() => {
        (async () => {
           try {
               const {data} = await Api.singleIvr(open)
               setForm({
                   ivr: data.single.name,
                   private_num: data.single.virtual,
                   no_answer: data.single.noaction,
                   time: data.single.item.map(i => ({
                       id:i.id,
                       num: i.buddy,
                       weekStart: weekDays[+i.days.split('-')[0] - 1][+localStorage.getItem('atsLang')  || 1],
                       weekEnd: weekDays[+i.days.split('-')[1] - 1][+localStorage.getItem('atsLang')  || 1],
                       timeStart: i.hours.split('-')[0],
                       timeEnd: i.hours.split('-')[1],
                       rx: i.rx,
                       tx: i.tx,
                   })),
                   sec:data.single.timeout
               })

           }catch (e) {
               console.log(e)
           }
            await dispatch(fileRequest())

        })()
    },[open])

    const getIvrPos = (id) => form.time.findIndex((ivr) => ivr.id === id);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id === over.id) return;
        setForm((tasks) => {
            const originalPos = getIvrPos(active.id);
            const newPos = getIvrPos(over.id);
            return {
                ...form,
                time:  arrayMove(tasks.time, originalPos, newPos)
            };

        });
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.ivr} onChange={(ev) => handleChange('ivr', ev.target.value)}/>
                        </label>
                        <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               {ivr.private[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input
                                type="number"
                                value={form.private_num}
                                   onChange={(ev) => handleChange('private_num', ev.target.value)}/>
                        </label>
                        <label style={{margin:'20px 0'}} className="col-md-9 count">
                            <span>
                               {short.no_answer[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input
                                type="number"
                                value={form.no_answer}
                                   onChange={(ev) => handleChange('no_answer', ev.target.value)}/>
                        </label>
                        <label style={{margin:'20px 0'}} className="col-md-3 count">
                            <span>
                               {short.sec[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input
                                type="number"
                                value={form.sec}
                                   onChange={(ev) => handleChange('sec', ev.target.value)}/>
                        </label>
                    </div>
                    <div className="short_block" style={{margin: '30px 0',}}>
                        <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                            <div style={{width: 150}}>
                                {ivr.file[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 50,margin: '0 10px'}}>
                                RX
                            </div>
                            <div style={{width: 50,margin: '0 10px'}}>
                                TX
                            </div>
                            <div style={{width: 150}}>
                                {short.week[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 150}}>
                                {short.time[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 150}}>
                                {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                        </div>
                        <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                            <div >
                                <SortableContext  items={form.time} strategy={verticalListSortingStrategy}>
                                    {form.time.map(s => (
                                        <IvrLine key={s.id}  fileData={fileData} s={s} handleDelete={handleDelete}/>
                                    ))}
                                </SortableContext>

                            </div>
                        </DndContext>

                        <div className="d-flex flex-row text-center" style={{width: 'fit-content',margin:'10px 0'}}>

                            <div style={{width: 150}}>
                                <Select
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    onChange={(ev) => handleTimeChange('num',  ev.value.split('*')[1], ev.value.split('*')[0])}
                                    menuPortalTarget={document.body}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    defaultValue={graphic.num + '*' + graphic?.notes}
                                    name="color"
                                    options={fileData.map(k => (
                                        {value:k.name + '*' + k.fname, label: `${k.name} ( ${k.fname} )`}
                                    ))}
                                />
                            </div>
                            <div className="count" style={{width: 50,margin: '0 10px'}}>
                               <input style={{textAlign:"center"}} value={graphic.rx} onChange={(ev) => handleTimeChange('rx',ev.target.value)}/>
                            </div>
                            <div className="count" style={{width: 50,margin: '0 10px'}}>
                                <input style={{textAlign:"center"}} value={graphic.tx} onChange={(ev) => handleTimeChange('tx',ev.target.value)}/>
                            </div>
                            <div style={{width: 150}}>
                                <select value={graphic.weekStart} onChange={(ev) => handleTimeChange('weekStart',ev.target.value)} style={{width: '40%', height: 35,textAlign:"center", fontSize:14}}>
                                    <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {weekDays.map(k => (
                                        <option key={k[+localStorage.getItem('atsLang')  || 1 ]} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                    ))}
                                </select>
                                <span>-</span>
                                <select value={graphic.weekEnd} onChange={(ev) => handleTimeChange('weekEnd',ev.target.value)} style={{width: '40%', height: 35,textAlign:"center", fontSize:14}}>
                                    <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {weekDays.map(k => (
                                        <option key={k[+localStorage.getItem('atsLang')  || 1 ]} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{width: 150}}>
                                <Flatpickr
                                    data-enable-time
                                    noCalendar
                                    style={{
                                        width: '40%',
                                        textAlign: 'center',
                                        border: '1px solid',
                                        height: 35,
                                        fontSize: 14
                                    }}
                                    value={graphic.timeStart}
                                    onChange={([ev]) => handleTimeChange('timeStart', ev)}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        allowInput: true,
                                    }}
                                />
                                <span>-</span>
                                <Flatpickr
                                    data-enable-time
                                    noCalendar
                                    style={{
                                        width: '40%',
                                        textAlign: 'center',
                                        border: '1px solid',
                                        height: 35,
                                        fontSize: 14
                                    }}
                                    value={graphic.timeEnd}
                                    onChange={([ev]) => handleTimeChange('timeEnd', ev)}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        allowInput: true,
                                    }}
                                />
                            </div>
                            <div style={{width: 150}} className="d-flex justify-content-center">
                                <button onClick={handleAddGraphic} className="addBtn">
                                    {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtnBig">
                            {global.edit[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditIvr;
