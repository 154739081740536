import {
    IVR_ADD_LOCAL, IVR_CREATE_SUCCESS, IVR_DELETE_LOCAL, IVR_EDIT_SUCCESS,
    IVR_FAIL,
    IVR_REQUEST,
    IVR_SUCCESS
} from '../actions/ivr';

const initialState = {
    ivrData: [],
    ivrStatus:'',
    page:1,
    count:0,
    totalPages: 1
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case IVR_REQUEST: {
            return {
                ...state,
                ivrStatus: 'request',
                ivrData: [],
            };
        }
        case IVR_SUCCESS: {
            return {
                ...state,
                ivrStatus: 'success',
                ivrData: action.payload.data.ivr,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case IVR_CREATE_SUCCESS: {
            return {
                ...state,
                ivrStatus: 'success',
                ivrData: action.payload.data.ivr,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case IVR_EDIT_SUCCESS: {
            return {
                ...state,
                ivrStatus: 'success',
                ivrData: action.payload.data.ivr,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case IVR_ADD_LOCAL: {
            return {
                ...state,
                ivrData: [...state.ivrData,{...action.payload.data,id:new Date()}],
            };
        }
        case IVR_DELETE_LOCAL: {
            return {
                ...state,
                ivrData: state.ivrData.filter(i => i.id !== action.payload.id,),
            };
        }

        case IVR_FAIL: {
            return {
                ...state,
                ivrStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
