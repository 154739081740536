import React, {useCallback, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Api from "../Api";
import Account from "../helpers/Account";
import {lang, login} from "../translate";
const {REACT_APP_SERVER} = process.env;
import InputMask from "react-input-mask";
import LogRegSide from "../components/LogRegSide";
import {useLocation} from "react-router-dom";
import qs from "query-string";
function Register(props) {
    const [phone, setUserName] = useState('374')
    const [password, setPassword] = useState('')
    const [show, setShow] = useState(false)
    const [lang1, setLang] = useState(1)
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            if (!phone) {
                toast.error('Enter phone number');
                return;
            }
            if(!localStorage.getItem('creating') && !query.phone){
                await Api.registerUser(phone.replaceAll(' ','').replace('(','').replace(')',''))
                localStorage.setItem('creating','true')
                setShow(true)

            }else{
                let {data} = await Api.registerUserVerify(phone.replaceAll(' ','').replace('(','').replace(')',''),password)
                Account.setAdminToken(data)

                localStorage.removeItem('creating')
                navigate(`/`)

            }
        } catch (e) {
            toast.error(e.response.data.message);
            localStorage.removeItem('creating')
            setShow(false)
        }
    }, [phone,password,location.search])


    useEffect(() =>{
        Account.logoutAdmin()
        localStorage.removeItem('creating')
    },[])
    useEffect(() =>{
        if(query.phone){
            setUserName(query.phone.toString())
            setShow(true)
        }
    },[location.search])
    const handleLangChange = useCallback((lang) => {
        setLang(lang)
        localStorage.setItem('atsLang',lang)
    }, [])
    const {REACT_APP_OPERATOR, REACT_APP_ATS} = process.env
    return (
        <>
            <Helmet>
                <title>{login.titleR[lang1]}</title>
            </Helmet>
            <div className="adminLogin row">
                <div className="loginContainer">
                    <div className="adminLoginHeader">
                        <div>
                        </div>
                        <div>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(3)}>
                               hy
                            </span>
                            <span style={{margin: '0 5px'}}>
                               |
                            </span>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(2)}>
                               ru
                            </span>
                            <span style={{margin: '0 5px'}}>
                               |
                            </span>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(1)}>
                               eng
                            </span>
                        </div>
                    </div>
                    <div className="adminLoginPage">

                        <form onSubmit={handleSubmit} className="adminLoginForm">

                            <div className="adminLoginMain">
                                <label
                                    className="adminInput"
                                ><InputMask
                                        mask="374 (99) 999999"
                                        maskChar="_"
                                        placeholder="374 (__) ______"
                                        onChange={(ev) => setUserName(ev.target.value)}
                                        value={phone}
                                    >
                                        {(inputProps) => <input {...inputProps} />}
                                    </InputMask>
                                </label>
                                {show?<label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                    <input
                                        id="adminPass"
                                        value={password}
                                        placeholder={login.pass[lang1]}
                                        type={show ? 'text' : 'password'}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />
                                </label>:null}
                                <button type="submit" onClick={handleSubmit} className="adminLoginBtn">{login.titleR[lang1]}</button>
                                <span>Already Have account? <Link to="/login">Login</Link></span>

                            </div>
                        </form>
                    </div>
                </div>
                {REACT_APP_SERVER === 'Beeline'?null: <LogRegSide/>
                }
            </div>
        </>

    );
}

export default Register;
