import Cookies from "js-cookie";

class Account {
    static getToken() {
        return localStorage.getItem('token') || sessionStorage.getItem('token') || '';
    }

    static getAdminToken() {
        return localStorage.getItem('access_token') || '';
    }
    static getUrsToken() {
        return localStorage.getItem('access_token_urs') || '';
    }

    static getAdminRefreshToken() {
        return localStorage.getItem('refresh_token') || '';
    }

    static getUrsRefreshToken() {
        return localStorage.getItem('refresh_token_urs') || '';
    }

    static setAdminToken(token) {
        localStorage.setItem('access_token', token.access_token);
        localStorage.setItem('refresh_token', token.refresh_token);
    }

    static setUrsAdminToken(token) {
        localStorage.setItem('access_token_urs', token.access_token);
        localStorage.setItem('refresh_token_urs', token.refresh_token);
    }

    static logoutAdmin() {
        localStorage.removeItem('access_token_urs');
        localStorage.removeItem('refresh_token_urs');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        Cookies.remove('key')
        Cookies.remove('original')
        Cookies.remove('viewer')
    }
}

export default Account;
