import React, {useCallback, useEffect, useRef, useState} from 'react';
import {historyHeader, input, lang,menu,logs} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Cookies from "js-cookie";
import {logRequest} from "../store/actions/logs";

function LogsFilter(props) {
    const dateInputStartRef = useRef(null);
    const dateInputEndRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        if (name === 'startTime' || name === 'endTime') {
            setFilters((prev) => ({
                ...prev,
                [name]: ev
            }));
            query[name] = ev
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
            query[name] = ev.target.value
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [location.search])
    useEffect(() => {
        $.datepicker.setDefaults($.datepicker.regional[lang[+localStorage.getItem('atsLang')]]);

        $(dateInputEndRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });
        $(dateInputStartRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });

        $(dateInputStartRef.current).on('change', function () {
            handleChange('startTime', $(this).val());
        });
        $(dateInputEndRef.current).on('change', function () {
            handleChange('endTime', $(this).val());
        });

    }, [location.search]);
    const dispatch = useDispatch()

    const [filters, setFilters] = useState({
        startTime: new Date().setDate(new Date().getDate() - 5),
        endTime: new Date().setDate(new Date().getDate() + 1),
        type:'',
        isAdmin:'',
        method:'',
    })


    useEffect(() => {
        let yourDate = new Date()
        const offset = yourDate.getTimezoneOffset()
        yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
        if (!_.isEmpty(query)) {
            setFilters({
                endTime: yourDate.toISOString().split('T')[0],
                startTime: yourDate.toISOString().split('T')[0],
                type:query.type || '',
                isAdmin:query.isAdmin || '',
                method:query.method || '',

            })
            if (query.endTime) {
                setFilters((prev) => ({
                    ...prev,
                    endTime: query.endTime
                }))
            }
            if (query.startTime) {
                setFilters((prev) => ({
                    ...prev,
                    startTime: query.startTime
                }))
            }
        } else {
            const fiveDaysAgo = new Date();
            fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
            query.startTime = fiveDaysAgo.toISOString().split('T')[0]
            query.endTime = yourDate.toISOString().split('T')[0]
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [])


    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(logRequest(Cookies.get("history") || query.limit || 10, query.page || 1, filters));
    }, [filters])


    return (
        <div className="row" style={{margin: 2, alignItems: 'end'}}>
            <div className="col-md-12">
                <div className="row history_page">
                    <label className="col-md-6">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.start[+localStorage.getItem('atsLang')  || 1]}</span>
                            <input
                                ref={dateInputStartRef}
                                type="text"
                                value={query.startTime}
                            />
                        </div>
                    </label>
                    <label className="col-md-6">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.end[+localStorage.getItem('atsLang')  || 1]}</span>
                            <input
                                ref={dateInputEndRef}
                                type="text"
                                value={query.endTime}
                            />
                        </div>
                    </label>
                    <label className="col-md-6">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{logs.changer[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.isAdmin} onChange={(e) => handleChange('isAdmin', e)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="admin" id="1">{logs.admin[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="user" id="2">{logs.user[+localStorage.getItem('atsLang')  || 1]}</option>

                            </select>
                        </div>
                    </label>
                    <label className="col-md-6">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{logs.method[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.method} onChange={ev => handleChange('method', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="POST" id="1">POST</option>
                                <option value="PUT" id="2">PUT</option>
                                <option value="GET" id="2">GET</option>
                                <option value="DELETE" id="2">DELETE</option>
                            </select>
                        </div>
                    </label>
                    <label className="col-md-8">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{logs.source[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.type} onChange={ev => handleChange('type', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="sip" id="1">{menu.sip[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="included" id="1">{menu.included[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="short" id="1">{menu.short[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="ivr" id="1">{menu.ivr[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="did" id="1">{menu.did[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="provider" id="1">{menu.provide[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="file" id="1">{menu.file[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="login" id="1">Login</option>

                            </select>
                        </div>
                    </label>
                    <label className="col-md-4">
                        <button className="addBtnBig" onClick={handleSubmit} style={{
                            fontSize: 11,
                            padding: '10px 17px'
                        }}>
                            {input.search[+localStorage.getItem('atsLang')  || 1]}
                        </button>
                    </label>
                </div>
            </div>

        </div>
    );
}

export default LogsFilter;
