import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {did, included, input, menu, profile, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {didCreate, didCreateGet, didEdit} from "../store/actions/did";
import Api from "../Api";
import TelegramCode from "./TelegramCode";
import TableRow from "@mui/material/TableRow";
import {Link} from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import moment from "moment";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function SingleDidNumbers({open, edit, setOpen}) {
    const [form, setForm] = useState({
        operator: '',
        note: '',
        phone: '',
        profile: '',
        serverHost: '',
        username: '',
        password: '',
        line: '1'
    })

    const dispatch = useDispatch()
    const didCreateData = useSelector(state => state.did.didCreateData)
    useEffect(() => {
        if (edit) {
            (async () => {
                const [did,provider] = edit.split('_')
               try {
                   const {data} = await Api.singleDid(did,provider)
                   setForm(data.did)
               }catch (e) {

               }
            })()
        }
    }, [edit])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                {menu.did[+localStorage.getItem('atsLang')  || 1]}
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            x
                        </span>
                        </div>

                        <div id="transition-modal-description sip_content"  style={{marginTop: 20}}>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{sip.phone[+localStorage.getItem('atsLang')  || 1]} </b> - {form.pref + form.did}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{sip.forward[+localStorage.getItem('atsLang')  || 1]} </b> - {form.output?.output}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.line[+localStorage.getItem('atsLang')  || 1]} </b> - {form.dlines}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.used_lines[+localStorage.getItem('atsLang')  || 1]} </b> - {form.totalCount}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.calc[+localStorage.getItem('atsLang')  || 1]} </b> - <Link to={`/history?number=` + form.pref + form.did + '&startTime=' + moment().format('DD-MM-YYYY')}>{form.history}</Link></div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{sip.note[+localStorage.getItem('atsLang')  || 1]} </b> - {form.notes}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.active[+localStorage.getItem('atsLang')  || 1]} </b> : {form.in} - {form.out}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.date[+localStorage.getItem('atsLang')  || 1]} </b> : {form.inTime?.intime} - {form.outTime?.outtime}</div>
                            <div className="d-flex justify-content-center" style={{margin:'5px 0'}}><b style={{marginRight:5}}>{did.no_line[+localStorage.getItem('atsLang')  || 1]} </b> : {form.nodline}</div>
                        </div>
                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={() => setOpen('')} className="addBtn">
                                OK
                            </button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>

    );
}

export default SingleDidNumbers;
