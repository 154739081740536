export const HISTORY_REQUEST = 'HISTORY_REQUEST';
export const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
export const HISTORY_FAIL = 'HISTORY_FAIL';

export function historyRequest(rowsPerPage,page,search) {
    return {
        type: HISTORY_REQUEST,
        payload: {rowsPerPage,page,search },
    };
}


export const HISTORY_EXEL_REQUEST = 'HISTORY_EXEL_REQUEST';
export const HISTORY_EXEL_SUCCESS = 'HISTORY_EXEL_SUCCESS';
export const HISTORY_EXEL_FAIL = 'HISTORY_EXEL_FAIL';

export function historyExelRequest(rowsPerPage,page,search) {
    return {
        type: HISTORY_EXEL_REQUEST,
        payload: {rowsPerPage,page,search },
    };
}
