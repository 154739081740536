import {all, fork} from 'redux-saga/effects';
import forward from './forward';
import crm from './crm';
import shortNumber from './shortNumber';
import ivr from './ivr';
import history from './history';
import included from "./included";
import did from "./did";
import sip from "./sip";
import callRout from "./callRout";
import autoCall from "./autoCall";
import blocked from "./blocked";
import file from "./file";
import ip from "./ip";
import users from "./users";
import logs from "./logs";


export default function* watchers() {
    yield all([
        users,
        forward,
        crm,
        shortNumber,
        ivr,
        history,
        included,
        did,
        sip,
        callRout,
        autoCall,
        file,
        ip,
        blocked,
        logs
    ].map(fork));
}
